<template>
  <hcc-modal
    :title="titleModal"
    class="shop"
    :width="600"
    name="product-form-options"
    @confirm="createOption"
    @cancel="$emit('cancel')"
    @opened="focusBuildModal"
    :confirmDisableButton="!validForm"
    :adaptive="true"
  >
    <div class="user__property">
      <hcc-input
        :placeholder="$t('shop.product.options.list')"
        v-model="newOption.lista"
        @blur="$v.newOption.lista.$touch()"
        :error="$v.newOption.lista.$error"
        :errorMessage="
          $t('shop.errors.required', { field: $t('shop.product.options.list') })
        "
        :label="$t('shop.product.options.list')"
        ref="hccInput"
      />
      <hcc-input
        type="number"
        :placeholder="$t('shop.product.options.inventory')"
        v-model="newOption.inventario"
        :label="$t('shop.product.options.inventory')"
      />
      <hcc-money
        :placeholder="$t('shop.product.options.extra-cost')"
        v-model="newOption.costoExtra"
        @blur="$v.newOption.costoExtra.$touch()"
        :error="$v.newOption.costoExtra.$error"
        :errorMessage="
          $t('shop.errors.required', {
            field: $t('shop.product.options.extra-cost'),
          })
        "
        :label="$t('shop.product.options.extra-cost')"
      />
      <hcc-money
        :placeholder="$t('shop.product.options.purchase')"
        v-model="newOption.compra"
        @blur="$v.newOption.compra.$touch()"
        :error="$v.newOption.compra.$error"
        :errorMessage="
          $t('shop.errors.required', {
            field: $t('shop.product.options.purchase'),
          })
        "
        :label="$t('shop.product.options.purchase')"
      />
      <div class="shop__container">
        <div class="shop__group">
          <span>{{ $t('shop.product.options.active') }}</span>
          <div class="shop__status">
            <hcc-toggle-switch
              name="toggle-activo"
              :value="newOption.activo"
              @change="toggleStatus"
            />
          </div>
        </div>
      </div>
    </div>
  </hcc-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import HccModal from '@/components/shared/HccModal/index.vue';
import HccInput from '@/components/shared/HccInput/index.vue';
import HccMoney from '@/components/shared/HccMoney/index.vue';
import HccToggleSwitch from '@/components/shared/HccToggleSwitch/index.vue';

export default {
  components: {
    HccModal,
    HccInput,
    HccMoney,
    HccToggleSwitch,
  },
  props: {
    option: {
      require: true,
    },
    save: {
      default: false,
    },
  },
  data() {
    return {
      newOption: {
        activo: false,
        costoExtra: 0,
        compra: 0,
      },
    };
  },
  validations: {
    newOption: {
      lista: { required },
      costoExtra: { required },
      compra: { required },
    },
  },
  computed: {
    validForm() {
      return !this.$v.$invalid;
    },
    titleModal() {
      return this.newOption.id
        ? this.$t('shop.product.options.title-form-edit')
        : this.$t('shop.product.options.title-form');
    },
  },
  watch: {
    option(newValue) {
      if (newValue) {
        this.newOption = { ...newValue };
      } else {
        this.resetData();
      }
    },
  },
  methods: {
    async createOption() {
      this.$emit('save', this.newOption);
      this.resetData();
    },
    focusBuildModal() {
      this.$refs.hccInput.focus();
    },
    toggleStatus() {
      this.newOption.activo = !this.newOption.activo;
    },
    resetData() {
      this.newOption = { activo: false, costoExtra: 0, compra: 0 };
      this.$v.$reset();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_user.scss";
@import "~styles/views/_shop.scss";
</style>
